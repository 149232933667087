.errorContainer {
  display: flex;
  flex-direction: column;
}

.errorContentBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 100px;
  /* width: 80%; */
}

.errorTitle {
  font-size: 5rem;
  font-weight: 600;
}

.errorMessage {
  font-size: 1rem;
  display: block;
  text-align: center;
  line-height: 2rem;
}

.errorButton {
  width: 50%;
  margin: 3rem 0 0 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: white;
  border: 1px solid #343a40;
  border-radius: 1;
  color: #343a40;
  font-weight: 600;
  cursor: pointer;
  transition: 0.1s;
  -o-transition: 0.1s;
  -ms-transition: 0.1s;
  -moz-transition: 0.1s;
  -webkit-transition: 0.1s;
}

.errorButton:hover {
  background-color: #545b62;
}

.errorButton:focus {
  outline: none;
}

@media screen and (max-width: 993px) {
  .errorContentBody {
    min-height: calc(100vh - 80px);
    padding-bottom: 10rem;
  }

  .errorButton {
    width: 100%;
  }
}
