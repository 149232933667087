.techniquesList {
  background-color: white;
  z-index: 1060;
  border: 1px solid #ccc;
  border-top: none;
  line-height: 250%;
  width: calc(24.5vw + 50px);
  padding: 0.5rem 0 0.5rem 0;
}

.techniquesTitle {
  padding: 0.3rem 0 0.3rem 1rem;
  font-size: 0.8rem;
  margin: -0.5rem 0 -0.5rem 0;
  text-align: left;
  line-height: 200%;
}

.borderLine {
  border-bottom: 0.5px solid #ccc;
  width: 95%;
  left: 3%;
  position: relative;
  text-align: center;
}

.techniquesTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.3rem 0 0.3rem;
}

.keywordTitle {
  width: 17%;
}

.example {
  width: 28%;
}

.description {
  width: 55%;
}

.techniques {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  padding: 0 0.3rem 0 0.3rem;
}

.keyword {
  font-size: 1rem;
  font-weight: 500;
  width: 17%;
}

.exampleKeyword {
  width: 28%;
  line-height: 130%;
}

.descriptionKeyword {
  width: 55%;
  line-height: 130%;
  text-align: left;
}

.keywordMinus {
  font-size: 2rem;
  width: 15%;
  text-align: center;
}

.techniquesListOpen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

.strongText {
  font-weight: 700;
}

.textDecoration {
  text-decoration: underline;
}

@media screen and (max-width: 993px) {
  .description {
    display: none;
  }

  .descriptionKeyword {
    display: none;
  }

  .techniquesList {
    z-index: 1060;
    line-height: 200%;
    width: 100%;
    margin: auto;
  }

  .techniquesTitle {
    padding-left: 1rem;
    font-size: 0.8rem;
    margin: 0;
  }

  .techniquesTop {
    justify-content: space-evenly;
  }

  .techniques {
    justify-content: space-evenly;
  }

  .keywordTitle {
    font-size: 1rem;
    text-align: left;
  }

  .keyword {
    font-size: 1rem;
    width: 20%;
    text-align: center;
  }

  .example {
    font-size: 1rem;
    text-align: center;
  }

  .exampleKeyword {
    font-size: 0.8rem;
    width: 50%;
    text-align: center;
  }
}
