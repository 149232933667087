.mobileSearchbar {
  display: none;
}

.searchbarContanier {
  display: none;
  position: relative;
  border-bottom: 0.5px solid #ccc;
}

.searchLabel {
  margin: 0;
}

.searchIcon {
  height: 23px;
  padding-right: 8px;
}

.searchbar {
  width: 90%;
  height: 40px;
  font-size: 1rem;
  border: none;
  box-sizing: border-box;
  padding-left: 1rem;
  border-radius: 0;
  background-color: white;
}

.searchbarBtn {
  height: 40px;
  width: 10%;
  background-color: white;
  border: none;
  vertical-align: top;
}

.searchbarBtn ::hover {
  cursor: pointer;
}

.techniquesListOpen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

@media screen and (max-width: 993px) {
  .mobileSearchbar {
    display: block;
    margin-top: 40px;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  .searchbarContanier {
    display: block;
  }
}
