.mobileSearchbar {
  display: none;
}

.searchbarContanier {
  position: fixed;
  z-index: 1060;
  top: 10px;
  width: 30vw;
}

.searchbar {
  width: 24.5vw;
  height: 2.7rem;
  max-height: 65px;
  font-size: 1rem;
  border: 0.5px solid #9d9d9d;
  border-radius: 0;
  padding-left: 1rem;
  background-color: white;
}

.searchbarBtn {
  background-color: #002366;
  border: 0.5px solid #002366;
  width: 50px;
  height: 2.7rem;
  font-size: 1rem;
  color: white;
}

.searchbarBtn ::hover {
  cursor: pointer;
}

.techniquesListOpen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

@media screen and (max-width: 993px) {
  .mobileSearchbar {
    display: block;
    margin-top: 40px;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  .searchbarContanier {
    display: block;
  }
}
